.index-wrapper {
  .welcome--text {
    flex: 0 0 100%;
    font-size: 20px;
    color: $grey-color;
    line-height: 30px;
  }

  span {
    padding: .5rem;
    color: $grey-color;
    margin-left: .5rem;
    text-transform: uppercase;
    //border-radius: .25rem;
    //border: 1px solid $grey-color;
    cursor: pointer;
    font-weight: 550;

    &.active {
      //background-color: $grey-color;
      color: black;
      font-weight: 800;
      position: relative;

      &::after {
        color: black;
        content: '';
        position: absolute;
        bottom: 6px;
        left: .5rem;
        width: calc(100% - 1rem);
        height: 2px;
        background-color: black;
      }
    }
  }

  .gallery {
    @media (min-width: 1024px) {
      min-height: 800px;

    }

    img {
      cursor: pointer;
      //position: relative;
      //top: 0;
      //left: 0;
      box-shadow: 0 7px 15px -10px rgb(0, 0, 0 / 1);

      &:hover {
        box-shadow: 0 15px 30px -10px rgb(0, 0, 0 / 1);
        //position: relative;
        //top: -15px;
        //left: -15px;
      }

      transition: top .3s, left .5s, box-shadow .4s;
      animation-timing-function: ease-in-out;
    }
  }
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.portfolio-full {
  h2 {
    font-weight: 700;
    color: $main-green;
  }

  img {
    cursor: pointer;
    //position: relative;
    //top: 0;
    //left: 0;
    box-shadow: 0 7px 15px -10px rgb(0, 0, 0 / 1);

    &:hover {
      box-shadow: 0 15px 30px -10px rgb(0, 0, 0 / 1);
      //position: relative;
      //top: -15px;
      //left: -15px;
    }

    transition: top .3s, left .5s, box-shadow .4s;
    animation-timing-function: ease-in-out;
  }
}

section {
  padding: 25px;
}

.nav-arrows a.arrow {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  color: $light-green;

  &.prev {
    left: 0;
    top: 50%;
  }

  &.next {
    right: 0;
    top: 50%;

  }

}

.lg-prev, .lg-next {
  color: white;
  text-shadow: 0 0 2px black;
  font-size: 35px;
  display: block;
  font-weight: 900;
}

.lg-item {
  video, picture {
    @media (max-width: 992px) {
      padding: 0 2rem;
    }
  }
}

.edit-btn, .edit-skill-btn{
  outline: none!important;
  background-color: $main-green !important;
  color: white;
  &:focus{
    outline: none!important;
  }
}
.skill-wrapper{
  input, textarea, select {
    font-size: 16px;
    padding: .25rem;
    border-radius: .25rem;
    border-color: transparent;
    box-shadow: 0 0 5px $main-green;

    &:focus {
      outline: none;
      box-shadow: 0 0 10px $main-green;
      background-color: $grey-color-light;
    }
  }
}