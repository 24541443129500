.footer {
  width: 100%;
  background: #c8e1e5;
  &-container {
    margin: auto;
    width: 100%;
    max-width: 1650px;
  }
  .malej-nadpis{
    font-size: 28px;
  }
  .nadpis-footer {
    color: black;
    font-weight: 700;
  }
  .odkazy-footer {
    li,{
      &:hover {
        color: #04606C;
        @extend .transitions;
      }
    }
  }
  a{
    color: black;
    &:hover {
      color: black;
    }
  }
  ul {
    //li div{
    //  //width: fit-content;
    //}
    line-height: 2.4rem;
    @media (max-width: 1280px) {
      line-height: 2rem;
    }
    @media (max-width: 1024px) {
      line-height: 1.75rem;
    }
    color: black;
    .lajna-footer {
      //top: 50%;
      transform: translateY(50%);
      height: 2px;
      background-color:  #cfecf0;
      //@media (max-width: 1280px) {
      //  left: 50%;
      //  //top: 50%;
      //  transform: translate(-50%, -50%);
      //}
    }
  }
  .footer-form {
    .default-btn {
      border-radius: 5px;
      padding: 0.35rem 0;
      background-color: #cfeef1;
      transition: all 500ms;
      color: black !important;
      &:hover {
        background-color: $light-green;
      }
    }
    input,
    textarea {
      border-radius: 5px;
      font-weight: 300;
      padding: 0.35rem 0 0.35rem 0.6rem;
      outline: none;
      @media (max-width: 650px) {
        font-size: 0.95rem;
      }
    }
    input {
      &::placeholder {
        color: #afafaf;
      }
    }
    textarea {
      &::placeholder {
        padding-top: 0.2rem;
        color: #afafaf;
      }
    }
  }
  .form-wrapper {
    row-gap: 0.35rem;
  }
  //alerts

  .alert-footer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: -25rem;
    z-index: 100;
  }


}
.bg-footer-black  {
  background-color: #bdd0d6;
  ul {
    margin: auto;
    width: 100vw;
    max-width: 1650px;
  }
}

.yeetzone {
  transition: all 500ms;
  &:hover {
    color: $light-green;
  }
}
