.admin-wrapper {
  table {
    thead {
      background-color: $main-green;
    }

    button {
      border-radius: 5px;
      padding: 0.5rem;
      background-color: $light-green;
      transition: all 500ms;

      &:hover {
        background-color: $dark-green;
      }
    }
  }

  #addForm {
    padding-top: 3rem;

    input, textarea, select {
      border-color: transparent;
      box-shadow: 0 0 5px $main-green;

      &:focus {
        outline: none;
        box-shadow: 0 0 10px $main-green;
        background-color: $grey-color-light;
      }
    }

    select {
      background-color: white;

    }

    label {
      svg, p {
        color: $main-green;

        &:hover {
          color: $dark-green;
        }
      }
      &:hover {
        svg, p {
          color: $dark-green;
        }
      }
    }



    button.send {
      border-radius: 5px;
      padding: 0.5rem;
      background-color: $light-green;
      transition: all 500ms;

      &:hover {
        background-color: $dark-green;
      }
    }

    #image-preview, #main_image_preview, #other_images_preview {
      transition: display 300ms;
    }

    label.error {
      color: red;
    }
  }

  aside {
    .active {
      color: $light-green;
    }

    a:hover {
      color: $main-green;
      background-color: $grey-color-light;
    }
  }
}