.mainMenu {
  width: 100%;
  padding: 25px;
  height: auto;

  .container {
    @media (min-width: 640px) {

      max-width: 640px;

    }
    @media (min-width: 768px) {

      max-width: 768px;

    }
    @media (min-width: 1024px) {

      max-width: 1024px;

    }
    @media (min-width: 1280px) {
      max-width: 1280px;

    }

    @media (min-width: 1536px) {
      max-width: 1536px;
    }


    width: 100%;

  }

  .container ul {
    display: inline-flex;
    float: right;

    li {
      list-style-type: none;

      a {
        text-decoration: none;
        color: $black-color;
        padding: 50px 15px 50px 15px;
        margin-left: 25px;
        font-size: 20px;
        transition: 0.3s;

        &:hover {
          color: $main-green;
        }

        &.active {
          color: $dark-green;
          font-weight: 900;
        }
      }

      .mainBtn {
        font-weight: 900;
        color: $main-green;
      }
    }
  }

  .container .logo {


    flex: 0 0 100%;

    img {
      max-width: 250px;
    }
  }
}

nav {
  .mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: $light-green;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
  }

  .mobile-menu.is-open {
    opacity: 1;
    z-index: 10000;
    visibility: visible;
  }

  /* Yellow band effect */
  .mobile-menu::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: $dark-green;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .4s .1s;
  }

  .mobile-menu.is-open::before {
    transform: skew(-14deg) translateX(0);
  }

  /* Skewing effect on menu links */
  .mobile-menu ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: center;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
  }

  .mobile-menu li {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
  }

  /* Apparition effect on links */
  .mobile-menu a {
    opacity: 0;
    transform: translateY(-10px);
  }

  .mobile-menu.is-open a {
    opacity: 1;
    transform: translateY(0);
  }

  .mobile-menu li:nth-child(1) a {
    transition: all 275ms 175ms
  }

  .mobile-menu li:nth-child(2) a {
    transition: all 275ms 225ms
  }

  .mobile-menu li:nth-child(3) a {
    transition: all 275ms 275ms
  }

  .mobile-menu li:nth-child(4) a {
    transition: all 275ms 325ms
  }

  .mobile-menu li:nth-child(5) a {
    transition: all 275ms 375ms
  }


  /* Decoration */
  .mobile-menu ul,
  .mobile-menu li {
    list-style: none;
    padding: 0;
  }

  .mobile-menu a {
    display: block;
    padding: 12px 0;
    color: $white-color;
    font-size: 1.4em;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: $light-green;
    }
  }

  /* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
  .open-main-nav {
    position: absolute;
    top: 15px;
    padding-top: 20px;
    right: 15px;
    z-index: 100000;
    background: none;
    border: 0;
    cursor: pointer;
  }

  .open-main-nav:focus {
    outline: none;
  }

  .burger {
    position: relative;
    display: block;
    width: 28px;
    height: 4px;
    margin: 0 auto;
    background: $light-green;
    transform: skew(5deg);
    transition: all .4s;
  }

  .burger:after,
  .burger:before {
    content: '';
    display: block;
    height: 100%;
    background: $light-green;
    transition: all .4s;
  }

  .burger:after {
    transform: translateY(-12px) translateX(-2px) skew(-20deg);
  }

  .burger:before {
    transform: translateY(-16px) skew(-10deg);
  }

  /* Toggle State part */
  .is-open .burger {
    transform: skew(5deg) translateY(-8px) rotate(-45deg);
  }

  .is-open .burger:before {
    transform: translateY(0px) skew(-10deg) rotate(75deg);
  }

  .is-open .burger:after {
    transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
  }
}

section, footer {
  @media (min-width: 768px) {
    display: block !important;
  }
}
