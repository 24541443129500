* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}
$dark-green : #2c5159;
$light-green : #3f97a8;
$main-green : #366d78;
$black-color: #1A1A1A;
$grey-color: #707070;
$grey-color-light: #f1f1f1;
$white-color: #fff;


nav .container {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  max-width: 1180px;
}
.login-button{
  background-color: $light-green !important;
  &:hover{
    background-color: $dark-green !important;
  }
  transition: background-color 500ms;
}
