.transitions {
  transition: all 0.25s ease-in-out;
}
@import 'components/lightgallery-bundle.min';
@import 'variables';
@import 'pages/index';
@import 'pages/about_me';
@import 'pages/blog';
@import 'pages/admin';
@import 'components/header';
@import 'components/footer';
@import "~bootstrap/scss/bootstrap";
