.blog-container{
  .blog-card{
    @media (max-width: 768px) {
      div:nth-child(1){
        padding-top: 56.25%;
        position: relative;
      }
      img{
        position: absolute;
        max-height: 100%;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }
  .blogItem {
    transition: all 500ms;
    max-height: max-content !important;
    height: 40rem;
    @media (min-width: 2100px) {
      height: 45rem;
    }
    @media (min-width: 2150px) {
      height: 50rem;
    }
    @media (min-width: 2180px) {
      height: 53rem;
    }
    @media (max-width: 1280px) {
      height: 35rem;
    }
    @media (max-width: 600px) {
      height: 30rem;
    }
    @media (max-width: 400px) {
      height: 28rem;
    }
    @media (max-width: 360px) {
      height: 30rem;
    }
    border: 0.5px solid transparent;
    padding: 1rem;
    .line {
      transition: all 500ms;
    }
    &:hover {
      border: 0.5px solid rgb(238, 237, 237) !important;
      .line1 {
        height: 1rem !important;
      }
      .line2 {
        width: 1rem !important;
      }
    }
    h1 {
      font-weight: 900;
      font-size: clamp(1.5rem, 1.6vw, 2.5rem);
      color: black;
    }
    p {
      font-size: clamp(1.1rem, 1vw, 1.45rem);
      line-height: 1.6;
      font-weight: 400;
    }
    .text {
      height: 30%;
      @media (max-width: 1840px) {
        height: 27%;
      }
      @media (max-width: 1600px) {
        height: 30%;
      }
      @media (max-width: 768px) {
        height: 25%;
      }
      @media (max-width: 600px) {
        height: 35%;
      }
      @media (max-width: 500px) {
        height: 30%;
      }
      @media (max-width: 460px) {
        height: 35%;
      }
      @media (max-width: 400px) {
        height: 40%;
      }
    }
    .image {
      position: relative;
      height: 70%;
      @media (max-width: 1840px) {
        height: 73%;
      }
      @media (max-width: 1600px) {
        height: 70%;
      }
      @media (max-width: 768px) {
        height: 75%;
      }
      @media (max-width: 600px) {
        height: 65%;
      }
      @media (max-width: 500px) {
        height: 70%;
      }
      @media (max-width: 460px) {
        height: 65%;
      }
      @media (max-width: 400px) {
        height: 60%;
      }
      img {
        height: 100%;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        position: relative !important;
        object-fit: cover;
      }
    }
  }
  .blog-tiny img{
    padding: .5rem;
  }
  &-container {
    h1 {
      font-weight: 900;
      font-size: clamp(1.7rem, 2.5vw, 3.9rem);
      color: black;
      line-height: 1.7;
      @media (max-width: 1024px) {
        line-height: 1.1;
      }
    }
    p {
      font-size: clamp(1.2rem, 1.1vw, 1.65rem);
      line-height: 1.6;
      font-weight: 300;
    }
    .filter {
      font-size: clamp(1.2rem, 1.1vw, 1.65rem);
      .filtry {
        -webkit-box-shadow: -12px 16px 15px -1px rgba(0,0,0,0.10);
        box-shadow: -12px 16px 15px -1px rgba(0,0,0,0.10);
        border-right: 1px solid white !important;
        img {
          height: 1.5rem;
        }
        @media (max-width: 1800px) {
          img {
            height: 1rem;
          }
        }
      }
      .razeni {
        -webkit-box-shadow: 12px 16px 15px -1px rgba(0,0,0,0.10);
        box-shadow: 12px 16px 15px -1px rgba(0,0,0,0.10);
        ol {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          height: 0;
          overflow: hidden;
        }
      }
    }
    .filtry, .razeni {
      font-weight: 300;
      .border-div {
        width: 100%;
        background: black;
        border-radius: 1px;
        input {
          background-color: transparent;
          color: white;
          font-weight: 300 !important;
          width: 100%;
          &::placeholder {
            color: white;
          }
        }
      }
      li {
        transition: all 500ms;
        color: black;
        background-color: white;
        &:hover {
          background-color: rgb(223, 220, 220);
        }
      }
    }
  }
  .activeRazeni {
    background-color: rgb(197, 196, 196) !important;
  }
}
.blogDetail {
  &-container {
    h1 {
      font-weight: 900;
      font-size: clamp(1.7rem, 2.5vw, 3.9rem);
      color: black;
      line-height: 1.7;
      @media (max-width: 1024px) {
        line-height: 1.1;
      }
    }
    p {
      font-size: clamp(1.2rem, 1.1vw, 1.65rem);
      line-height: 1.6;
      font-weight: 300;
    }
    .blog {
      .image {
        height: 40rem !important;
        max-height: 40rem !important;
        position: relative !important;
        @media (max-width: 800px) {
          height: 25rem !important;
        }
        @media (max-width: 500px) {
          height: 20rem !important;
        }
        @media (max-width: 400px) {
          height: 16rem !important;
        }
        img {
          height: 100%;
          max-height: 100%;
          width: 100%;
          max-width: 100%;
          position: relative !important;
          object-fit: cover;
          object-position: center center !important;
        }
      }
    }
  }
}

.another-blogs a{
  color: $grey-color !important;
  &:hover{
    color: $main-green !important;
  }
}
img.mymce-image-left{
  float: left;
  margin: .25rem;
  display: inline-block;
}
.blog-tiny-wrapper{
  img{
    padding: .25rem;
  }
}