@font-face {
  font-family: lg;
  src: url(../fonts/lg.woff2?io9a6k) format("woff2"), url(../fonts/lg.ttf?io9a6k) format("truetype"), url(../fonts/lg.woff?io9a6k) format("woff"), url(../fonts/lg.svg?io9a6k#lg) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block
}

.lg-icon {
  font-family: lg !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.lg-container {
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
}

.lg-next, .lg-prev {
  background-color: rgba(0, 0, 0, .45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: 0;
  border: none
}

.lg-next.disabled, .lg-prev.disabled {
  opacity: 0 !important;
  cursor: default
}

.lg-next:hover:not(.disabled), .lg-prev:hover:not(.disabled) {
  color: #fff
}

.lg-single-item .lg-next, .lg-single-item .lg-prev {
  display: none
}

.lg-next {
  right: 20px
}

.lg-next:before {
  content: '\e095'
}

.lg-prev {
  left: 20px
}

.lg-prev:after {
  content: '\e094'
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0
  }
  50% {
    left: -30px
  }
  100% {
    left: 0
  }
}

@-moz-keyframes lg-right-end {
  0% {
    left: 0
  }
  50% {
    left: -30px
  }
  100% {
    left: 0
  }
}

@-ms-keyframes lg-right-end {
  0% {
    left: 0
  }
  50% {
    left: -30px
  }
  100% {
    left: 0
  }
}

@keyframes lg-right-end {
  0% {
    left: 0
  }
  50% {
    left: -30px
  }
  100% {
    left: 0
  }
}

@-webkit-keyframes lg-left-end {
  0% {
    left: 0
  }
  50% {
    left: 30px
  }
  100% {
    left: 0
  }
}

@-moz-keyframes lg-left-end {
  0% {
    left: 0
  }
  50% {
    left: 30px
  }
  100% {
    left: 0
  }
}

@-ms-keyframes lg-left-end {
  0% {
    left: 0
  }
  50% {
    left: 30px
  }
  100% {
    left: 0
  }
}

@keyframes lg-left-end {
  0% {
    left: 0
  }
  50% {
    left: 30px
  }
  100% {
    left: 0
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end .3s;
  -o-animation: lg-right-end .3s;
  animation: lg-right-end .3s;
  position: relative
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end .3s;
  -o-animation: lg-left-end .3s;
  animation: lg-left-end .3s;
  position: relative
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4))
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  -webkit-transition: color .2s linear;
  -o-transition: color .2s linear;
  transition: color .2s linear;
  background: 0 0;
  border: none;
  box-shadow: none
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 18px
}

.lg-toolbar .lg-icon:hover {
  color: #fff
}

.lg-toolbar .lg-close:after {
  content: '\e070'
}

.lg-toolbar .lg-maximize {
  font-size: 22px
}

.lg-toolbar .lg-maximize:after {
  content: '\e90a'
}

.lg-toolbar .lg-download:after {
  content: '\e0f2'
}

.lg-sub-html {
  color: #eee;
  font-size: 16px;
  padding: 10px 40px;
  text-align: center;
  z-index: 1080;
  opacity: 0;
  -webkit-transition: opacity .2s ease-out 0s;
  -o-transition: opacity .2s ease-out 0s;
  transition: opacity .2s ease-out 0s
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0
}

.lg-sub-html a {
  color: inherit
}

.lg-sub-html a:hover {
  text-decoration: underline
}

.lg-media-overlap .lg-sub-html {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .6))
}

.lg-item .lg-sub-html {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0
}

.lg-error-msg {
  font-size: 14px;
  color: #999
}

.lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  height: 47px;
  vertical-align: middle
}

.lg-closing .lg-next, .lg-closing .lg-prev, .lg-closing .lg-sub-html, .lg-closing .lg-toolbar {
  opacity: 0;
  -webkit-transition: -webkit-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -moz-transition: -moz-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  -o-transition: -o-transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear;
  transition: transform .08 cubic-bezier(0, 0, .25, 1) 0s, opacity .08 cubic-bezier(0, 0, .25, 1) 0s, color .08 linear
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  -moz-transform: scale3d(.5, .5, .5);
  -o-transform: scale3d(.5, .5, .5);
  -ms-transform: scale3d(.5, .5, .5);
  -webkit-transform: scale3d(.5, .5, .5);
  transform: scale3d(.5, .5, .5);
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, .25, 1) 0s, opacity 250ms cubic-bezier(0, 0, .25, 1) !important
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap, body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1)
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: 3px;
  outline: 1px dashed rgba(255, 255, 255, .6)
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: 8px;
  outline-offset: -5px
}

.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  width: 100%;
  max-height: 350px;
  overflow: hidden;
  float: left
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important
}

.lg-outer .lg-thumb-outer.lg-rebuilding-thumbnails .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important
}

.lg-outer .lg-thumb-outer.lg-thumb-align-middle {
  text-align: center
}

.lg-outer .lg-thumb-outer.lg-thumb-align-left {
  text-align: left
}

.lg-outer .lg-thumb-outer.lg-thumb-align-right {
  text-align: right
}

.lg-outer.lg-single-item .lg-thumb-outer {
  display: none
}

.lg-outer .lg-thumb {
  padding: 5px 0;
  height: 100%;
  margin-bottom: -5px;
  display: inline-block;
  vertical-align: middle
}

@media (min-width: 768px) {
  .lg-outer .lg-thumb {
    padding: 10px 0
  }
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border-radius: 2px;
  margin-bottom: 5px;
  will-change: border-color
}

@media (min-width: 768px) {
  .lg-outer .lg-thumb-item {
    border-radius: 4px;
    border: 2px solid #fff;
    -webkit-transition: border-color .25s ease;
    -o-transition: border-color .25s ease;
    transition: border-color .25s ease
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0
}

.lg-outer .lg-toggle-thumb:after {
  content: '\e1ff'
}

.lg-outer.lg-animate-thumb .lg-thumb {
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.lg-outer .lg-video-cont {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative
}

.lg-outer .lg-video-cont .lg-object {
  width: 100% !important;
  height: 100% !important
}

.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto
}

.lg-outer .lg-video-object {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 3
}

.lg-outer .lg-video-poster {
  z-index: 1
}

.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity .3s ease-in;
  -o-transition: opacity .3s ease-in;
  transition: opacity .3s ease-in
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button, .lg-outer .lg-has-video.lg-video-loaded .lg-video-poster {
  opacity: 0 !important
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px
  }
}

@keyframes lg-play-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -moz-transition: -moz-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  -o-transition: -o-transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s;
  transition: transform .25s cubic-bezier(.17, .88, .32, 1.28), opacity .1s
}

.lg-video-play-button:hover .lg-video-play-icon, .lg-video-play-button:hover .lg-video-play-icon-bg {
  opacity: 1
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: .6;
  will-change: opacity;
  -webkit-transition: opacity .12s ease-in;
  -o-transition: opacity .12s ease-in;
  transition: opacity .12s ease-in
}

.lg-video-play-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: none;
  stroke-width: 3%;
  stroke: rgba(30, 30, 30, .9);
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200
}

.lg-video-play-icon {
  position: absolute;
  width: 25%;
  max-width: 120px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: .6;
  will-change: opacity;
  -webkit-transition: opacity .12s ease-in;
  -o-transition: opacity .12s ease-in;
  transition: opacity .12s ease-in
}

.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: lg-play-rotate 2s linear .25s infinite, lg-play-stroke 1.5s ease-in-out .25s infinite
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%) scale(.7)
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1
}

.lg-autoplay-button:after {
  content: '\e01d'
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: '\e01a'
}

.lg-single-item .lg-autoplay-button {
  opacity: .75;
  pointer-events: none
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s;
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s
}

.lg-outer.lg-use-transition-for-zoom.lg-zoom-drag-transition .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  will-change: transform;
  -webkit-transition: -webkit-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  -o-transition: -o-transform .8s cubic-bezier(0, 0, .25, 1) 0s;
  transition: transform .8s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img, .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -moz-transition: -moz-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -o-transition: -o-transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  transition: transform .5s cubic-bezier(.12, .415, .01, 1.19) 0s, opacity .15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden
}

.lg-icon.lg-zoom-in:after {
  content: '\e311'
}

.lg-icon.lg-actual-size {
  font-size: 20px
}

.lg-icon.lg-actual-size:after {
  content: '\e033'
}

.lg-icon.lg-zoom-out {
  opacity: .5;
  pointer-events: none
}

.lg-icon.lg-zoom-out:after {
  content: '\e312'
}

.lg-zoomed .lg-icon.lg-zoom-out {
  opacity: 1;
  pointer-events: auto
}

.lg-outer.lg-first-slide-loading .lg-actual-size, .lg-outer.lg-first-slide-loading .lg-zoom-in, .lg-outer.lg-first-slide-loading .lg-zoom-out, .lg-outer[data-lg-slide-type=iframe] .lg-actual-size, .lg-outer[data-lg-slide-type=iframe] .lg-zoom-in, .lg-outer[data-lg-slide-type=iframe] .lg-zoom-out, .lg-outer[data-lg-slide-type=video] .lg-actual-size, .lg-outer[data-lg-slide-type=video] .lg-zoom-in, .lg-outer[data-lg-slide-type=video] .lg-zoom-out {
  opacity: .75;
  pointer-events: none
}

.lg-outer .lg-pager-outer {
  text-align: center;
  z-index: 1080;
  height: 10px;
  margin-bottom: 10px
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible
}

.lg-outer.lg-single-item .lg-pager-outer {
  display: none
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  will-change: transform, opacity;
  -webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
  -moz-transition: opacity .15s ease 0s, -moz-transform .15s ease 0s;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  transition: opacity .15s ease 0s, transform .15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0)
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, .5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, .7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow .3s ease 0s;
  -o-transition: box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s;
  width: 12px
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0
}

.lg-fullscreen:after {
  content: "\e20c"
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"
}

.lg-outer .lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, .25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -webkit-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  transition: visibility 0s linear .18s, opacity .18s linear 0s
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active .lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible
}

.lg-outer.lg-dropdown-active .lg-share {
  color: #fff
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  will-change: visibility, opacity, transform;
  -webkit-transition: -webkit-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -moz-transition: -moz-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  transition: transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s
}

.lg-outer .lg-dropdown:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0
}

.lg-outer .lg-dropdown > li:hover a {
  color: #333
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, .07)
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto
}

.lg-outer .lg-share {
  position: relative
}

.lg-outer .lg-share:after {
  content: '\e80d'
}

.lg-outer .lg-share-facebook .lg-icon {
  color: #3b5998
}

.lg-outer .lg-share-facebook .lg-icon:after {
  content: '\e904'
}

.lg-outer .lg-share-twitter .lg-icon {
  color: #00aced
}

.lg-outer .lg-share-twitter .lg-icon:after {
  content: '\e907'
}

.lg-outer .lg-share-pinterest .lg-icon {
  color: #cb2027
}

.lg-outer .lg-share-pinterest .lg-icon:after {
  content: '\e906'
}

.lg-comment-box {
  width: 420px;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  will-change: transform;
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -o-transition: -o-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  transition: transform .4s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-comment-box .lg-comment-title {
  margin: 0;
  color: #fff;
  font-size: 18px
}

.lg-comment-box .lg-comment-header {
  background-color: #000;
  padding: 12px 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0
}

.lg-comment-box .lg-comment-body {
  height: 100% !important;
  padding-top: 43px !important;
  width: 100% !important
}

.lg-comment-box .fb-comments {
  height: 100%;
  width: 100%;
  background:  no-repeat scroll center center #fff;
  overflow-y: auto;
  display: inline-block
}

.lg-comment-box .fb-comments[fb-xfbml-state=rendered] {
  background-image: none
}

.lg-comment-box .fb-comments > span {
  max-width: 100%
}

.lg-comment-box .lg-comment-close {
  position: absolute;
  right: 5px;
  top: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #999;
  will-change: color;
  -webkit-transition: color .2s linear;
  -o-transition: color .2s linear;
  transition: color .2s linear
}

.lg-comment-box .lg-comment-close:hover {
  color: #fff
}

.lg-comment-box .lg-comment-close:after {
  content: '\e070'
}

.lg-comment-box iframe {
  max-width: 100% !important;
  width: 100% !important
}

.lg-comment-box #disqus_thread {
  padding: 0 20px
}

.lg-outer .lg-comment-overlay {
  background-color: rgba(0, 0, 0, .25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -webkit-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  transition: visibility 0s linear .18s, opacity .18s linear 0s
}

.lg-outer .lg-comment-toggle:after {
  content: '\e908'
}

.lg-outer.lg-comment-active .lg-comment-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible
}

.lg-outer.lg-comment-active .lg-comment-toggle {
  color: #fff
}

.lg-outer.lg-comment-active .lg-comment-box {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.lg-outer .lg-img-rotate {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  -o-transition: -o-transform .4s cubic-bezier(0, 0, .25, 1) 0s;
  transition: transform .4s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-outer[data-lg-slide-type=iframe] .lg-flip-hor, .lg-outer[data-lg-slide-type=iframe] .lg-flip-ver, .lg-outer[data-lg-slide-type=iframe] .lg-rotate-left, .lg-outer[data-lg-slide-type=iframe] .lg-rotate-right, .lg-outer[data-lg-slide-type=video] .lg-flip-hor, .lg-outer[data-lg-slide-type=video] .lg-flip-ver, .lg-outer[data-lg-slide-type=video] .lg-rotate-left, .lg-outer[data-lg-slide-type=video] .lg-rotate-right {
  opacity: .75;
  pointer-events: none
}

.lg-rotate-left:after {
  content: '\e900'
}

.lg-rotate-right:after {
  content: '\e901'
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px
}

.lg-flip-ver:after {
  content: '\e903'
}

.lg-flip-hor:after {
  content: '\e902'
}

.lg-medium-zoom-item {
  cursor: zoom-in
}

.lg-medium-zoom .lg-outer {
  cursor: zoom-out
}

.lg-medium-zoom .lg-outer.lg-grab img.lg-object {
  cursor: zoom-out
}

.lg-medium-zoom .lg-outer.lg-grabbing img.lg-object {
  cursor: zoom-out
}

.lg-relative-caption .lg-outer .lg-sub-html {
  white-space: normal;
  bottom: auto;
  padding: 0;
  background-image: none
}

.lg-relative-caption .lg-outer .lg-relative-caption-item {
  opacity: 0;
  padding: 16px 0;
  transition: .5s opacity ease
}

.lg-relative-caption .lg-outer .lg-show-caption .lg-relative-caption-item {
  opacity: 1
}

.lg-group:after {
  content: '';
  display: table;
  clear: both
}

.lg-container {
  display: none;
  outline: 0
}

.lg-container.lg-show {
  display: block
}

.lg-on {
  scroll-behavior: unset
}

.lg-hide-sub-html .lg-sub-html, .lg-next, .lg-pager-outer, .lg-prev, .lg-toolbar {
  opacity: 0;
  will-change: transform, opacity;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s;
  transition: transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-show-in .lg-next, .lg-show-in .lg-pager-outer, .lg-show-in .lg-prev, .lg-show-in .lg-toolbar {
  opacity: 1
}

.lg-show-in.lg-hide-sub-html .lg-sub-html {
  opacity: 1
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0)
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0)
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0)
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0)
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: .001;
  outline: 0;
  will-change: auto;
  overflow: hidden;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.lg-outer.lg-zoom-from-image {
  opacity: 1
}

.lg-outer.lg-visible {
  opacity: 1
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing
}

.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: opacity 0s;
  -o-transition: opacity 0s;
  transition: opacity 0s;
  white-space: nowrap
}

.lg-outer .lg-item {
  display: none !important
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
  background: no-repeat scroll center center transparent
}

.lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0
}

.lg-outer .lg-item.lg-complete {
  background-image: none
}

.lg-outer .lg-item.lg-current {
  z-index: 1060
}

.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative
}

.lg-outer .lg-empty-html .lg-sub-html, .lg-outer .lg-empty-html.lg-sub-html {
  display: none
}

.lg-outer.lg-hide-download .lg-download {
  opacity: .75;
  pointer-events: none
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity .2s ease-out .15s
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  will-change: auto;
  -webkit-transition: opacity 333ms ease-in 0s;
  -o-transition: opacity 333ms ease-in 0s;
  transition: opacity 333ms ease-in 0s
}

.lg-backdrop.in {
  opacity: 1
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden
}

.lg-css3.lg-fade .lg-item {
  opacity: 0
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity .1s ease 0s;
  -moz-transition: opacity .1s ease 0s;
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s ease 0s
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  -o-transition: -o-transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s;
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275) 0s
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s
}

.lg-container {
  display: none
}

.lg-container.lg-show {
  display: block
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1
}

.lg-inline .lg-backdrop, .lg-inline .lg-outer {
  position: absolute
}

.lg-inline .lg-backdrop {
  z-index: 1
}

.lg-inline .lg-outer {
  z-index: 2
}

.lg-inline .lg-maximize:after {
  content: '\e909'
}

.lg-components {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  -webkit-transition: -webkit-transform .35s ease-out 0s;
  -moz-transition: -moz-transform .35s ease-out 0s;
  -o-transition: -o-transform .35s ease-out 0s;
  transition: transform .35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0
}