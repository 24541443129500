.about-me-wrapper {
  .welcome--text {
    font-size: 20px;
    color: $grey-color;
    line-height: 30px;
  }

  .grid {
    .col-span-1, .col-span-full, .col-span-2  {
      margin-top: 1.5rem;
    }

    h2 {
      font-size: 28px;
      margin-top: .25rem;
      font-weight: 700;
      margin-bottom: .25rem;
      width: fit-content;
    }

    ul {
      padding-left: .5rem;
      width: fit-content;

      li {
        i {
          color: $main-green;
          font-size: 16px;
        }

        font-size: 20px;
        color: $grey-color;
        line-height: 30px;
      }
    }

    p {
      flex: 0 0 100%;
      font-size: 20px;
      color: $grey-color;
      line-height: 30px;
      padding-left: .5rem;
      width: fit-content;

    }
  }

}
.skill-wrapper{
  input, textarea, select {
    font-size: 16px;
    padding: .25rem;
    border-radius: .25rem;
    border-color: transparent;
    box-shadow: 0 0 5px $main-green;

    &:focus {
      outline: none;
      box-shadow: 0 0 10px $main-green;
      background-color: $grey-color-light;
    }
  }
  h2{
    margin-top: 1.5rem!important;
    margin-bottom: 0 !important;
  }
  .col-span-1, .col-span-full, .col-span-2 {
    h2:nth-child(1){
      margin-top: 0 !important;
    }
  }
}